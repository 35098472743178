<template>
    <b-modal id="protocols-modal" ref="protocols-modal" centered hide-header hide-footer size="lg">
        <div class="modal-header">
            <div class="modal-title">Protocolos</div>
            <span class="icon-box">
                <CloseIcon class="icon stroke" @click="closeModal" />
            </span>
        </div>

        <div class="body pt-2">
            <div class="protocols">
                <div class="protocol" v-for="(protocol, index) in protocolsInformation" :key="index">
                    <div class="protocol-header py-3 px-2" v-b-toggle="`collapse-${index}`">
                        <div class="protocol-header__informations">

                            <div @click.stop v-b-toggle.disabled>
                                <b-form-checkbox type="checkbox" size="lg" class="mr-2" v-b-toggle.disabled @click.stop
                                    v-model="protocolsSelected" :value="protocol" />
                            </div>
                            <ProtocolIcon class="icon" v-b-tooltip="customTooltip"/>
                            <div class="col">
                                <div class="row">
                                    <div class="protocol-name">{{ protocol.name }}</div>
                                    <div class="separator mx-2">|</div>
                                    <div class="protocol-manufacturer">{{ protocol.manufacturer }}</div>
                                </div>
                                <div class="protocol-indicated" v-if="protocolIsIndicated(protocol.key, refractions, patientBirthday)">
                                    (Recomendado)
                                </div>

                            </div>
                        </div>
                        <ChevronDownIcon class="icon caretDownIcon" aria-hidden="true" />

                    </div>
                    <b-collapse :id="`collapse-${index}`" class="protocol-recomendations">
                        <div class="protocol-recomendation mb-3" v-for="(recomendation, index) in protocolsRecomendations[protocol.key]?.recomendations"
                            :key="index">
                            <div>
                                <span class="protocol-recomendation-name">
                                    {{ recomendation.name }}
                                </span>
                                <span>
                                    {{ recomendation.description }}
                                </span>
                            </div>
                        </div>
                        <div class="link">
                            <a :href="protocolsRecomendations[protocol.key]?.link" target="_blank">Saiba mais</a>
                        </div>
                    </b-collapse>
                </div>

                <div class="protocols-optional">
                    <b-form-checkbox size="lg" name="insert-patient-data" v-model="insertPatientData" />
                    <label @click="insertPatientData = !insertPatientData" for="insert-patient-data">Inserir dados do
                        paciente na impressão (Data de nascimento, CPF e
                        endereço)</label>
                </div>
            </div>


        </div>

        <div class="actions mt-2">
            <b-button class="borderless" variant="outline-primary" @click="cancelActions()">
                <span class="text-danger" v-if="hasChange">Cancelar</span>
                <span v-else>Voltar</span>
                 </b-button>
            <b-button class="ml-2" variant="primary" :disabled="protocolsSelected <= 0 && !hasChange" @click="confirmActions()">
                <span v-if="protocolsSelected?.length">({{ protocolsSelected.length }})</span>
                <span v-if="hasChange"> Atualizar protocolo</span>
                <span v-else> Utilizar protocolo</span>
            </b-button>
        </div>
    </b-modal>
</template>
<script>
import oculosProtocolsMixin from '@/mixins/oculosProtocols/oculosProtocolsMixin';
import CloseIcon from '@/assets/icons/close.svg';
import ChevronDownIcon from '@/assets/icons/chevron-down.svg';
import ProtocolIcon from '@/assets/icons/protocol.svg';
export default {
    components: {
        CloseIcon,
        ChevronDownIcon,
        ProtocolIcon
    },
    mixins: [oculosProtocolsMixin],
    props: {
        protocols: {
            type: Array,
            default: () => []
        },
        insertPatientDataProps: {
            type: Boolean,
            default: false
        },
        patientBirthday: {
            type: String,
            default: ''
        },
        refractions: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            insertPatientData: this.insertPatientDataProps,
            protocolsSelected: this.protocols,
            hasChange: false
        };
    },
    computed: {
        protocolsComputed: {
            get() {
                return this.protocolsSelected;
            },
            set(value) {
                
            }
        },
        customTooltip() {
            return (
              !this.value && {
                title: 'Protocolo do fabricante'
              }
            )
    }
    },
    methods: {
        closeModal() {
            this.$bvModal.hide('protocols-modal');
        },
        cancelActions() {
            this.protocolsSelected = this.protocols;
            this.insertPatientData = this.insertPatientDataProps;
            this.closeModal();
        },  
        confirmActions() {
            this.$emit('onChange', {protocols: this.protocolsSelected, insertPatientData: this.insertPatientData });
            this.closeModal();
        },
        show() {
        this.$refs['protocols-modal'].show()
      },
    },
    watch: {
        protocolsSelected: function (val) {
            this.hasChange = true
        },
        protocols: function (val) {
            this.protocolsSelected = val;
            this.hasChange = false
        },
        insertPatientDataProps: function (val) {
            this.insertPatientData = val;
        }
    }
};
</script>
<style lang="scss" scoped>
#protocols-modal {

    .modal-header {
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;

        .modal-title {
            font-size: 18px;
            color: #525c7a;
            font-weight: 600;
        }
    }

    .actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .borderless {
            border: none !important;
        }
    }

    .body {
        color: #525C7A;

        .protocols {

            .protocol {
                display: flex;
                flex-direction: column;

                border: 1px solid #F4F5FB;
                border-radius: 8px;

                margin-bottom: 5px;


                .protocol-header {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;
                    background-color: #F4F5FB;
                    color: #0C1D59;

                    .protocol-header__informations {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                    }

                    .separator {
                        color: #FF6B00;
                    }

                    &.collapsed {
                        border-bottom-left-radius: 8px;
                        border-bottom-right-radius: 8px;
                    }

                    .protocol-indicated {
                        color: #305BF2;
                        font-size: 12px;
                        font-style: italic;
                        font-weight: 400;
                    }
                }

                .not-collapsed {
                    .caretDownIcon {
                        transform: rotate(180deg);
                        transition: all .3s linear;
                    }
                }

                .collapsed {
                    .caretDownIcon {
                        transition: all .3s linear;
                    }
                }

                .protocol-recomendations {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    padding: 5px 5px;
                    padding-left: 30px;

                    .protocol-recomendation {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        flex: 1;

                        .protocol-recomendation-name {
                            font-weight: bold;
                        }
                    }
                }
            }

            .protocols-optional {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                margin-top: 20px;
                margin-left: 5px;

                label {
                    margin: 0;
                }
            }
        }

    }

    .icon {
        width: 26px;
    }

    .custom-tooltip {
        padding: 8px 16px;
        border-radius: 8px;
        border: 1px solid #ebebf2 !important;
        gap: 10px !important;
        background-color: #ffffff !important;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08) !important;
        .arrow {
          display: none;
        }
        .tooltip-inner {
          background-color: #ffffff !important;
          color: #4a4a4a !important;
        } 
    }
}
</style>
